let parallaxImages = []
let windowHeight = 0

document.addEventListener('DOMContentLoaded', function () {
  parallaxImages = document.querySelectorAll('.parallax')
  windowHeight = window.innerHeight

  // Attach the handleParallax function to the scroll and resize events
  window.addEventListener('scroll', handleParallax)
  window.addEventListener('resize', handleResize)
  handleParallax()

})

function handleResize () {
  windowHeight = window.innerHeight
  handleParallax()
}

// Function to handle the parallax effect
function handleParallax () {
  parallaxImages.forEach(img => {
    let parent = img.parentNode
    let rect = parent.getBoundingClientRect()

    if (rect.top <= windowHeight && rect.bottom >= 0) {

      let diffHeight = img.offsetHeight - parent.offsetHeight
      let stepsPerPixel = diffHeight / (windowHeight + parent.offsetHeight)

      // Calculate the offset for the parallax effect
      let offset = Math.abs((rect.top - windowHeight) * stepsPerPixel)

      // Apply the offset to the image's top position
      img.style.bottom = `-${offset}px`

      // Apply stepsPerPixel/2 to parallax__content children elements
      let contentElements = parent.querySelectorAll('.parallax__content')

      contentElements.forEach(content => {
        let scrollY = window.scrollY
        let contentOffsetY = scrollY * stepsPerPixel / 2
        content.style.transform = `translate3d(0, ${contentOffsetY}px, 0)`
      })
    }
  })
}
